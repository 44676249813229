import request from '@/utils/request'


// 查询新闻评论列表
export function listComment(query) {
  return request({
    url: '/news/newsComment/list',
    method: 'get',
    params: query
  })
}

// 查询新闻评论分页
export function pageComment(query) {
  return request({
    url: '/news/newsComment/page',
    method: 'get',
    params: query
  })
}

// 查询新闻评论详细
export function getComment(data) {
  return request({
    url: '/news/newsComment/detail',
    method: 'get',
    params: data
  })
}

// 新增新闻评论
export function addComment(data) {
  return request({
    url: '/news/newsComment/add',
    method: 'post',
    data: data
  })
}

// 修改新闻评论
export function updateComment(data) {
  return request({
    url: '/news/newsComment/edit',
    method: 'post',
    data: data
  })
}

// 删除新闻评论
export function delComment(data) {
  return request({
    url: '/news/newsComment/delete',
    method: 'post',
    data: data
  })
}
